<template>
  <div>
    <template v-if="setup && loaded">
      <router-view />
    </template>
    <template v-else>
      <pulse-loader />
      <v-app>
        <primeit-validate-current-time />
      </v-app>
    </template>
    <dav-timezone></dav-timezone>
  </div>
</template>

<script>
import PulseLoader from './components/PulseLoader';

import { Config, FeatureDetection } from '@dav/security-component-spa';
import merge from 'deepmerge';
import Vue from 'vue';

export default {
  components: { PulseLoader },
  data: () => ({
    loaded: false,
  }),
  computed: {
    setup() {
      return this.$store.getters['credential/SETUP'];
    },
    translation() {
      return this.$store.getters['credential/GET_TRANSLATION'];
    },
  },
  watch: {
    setup(value) {
      if (value && value.theme && value.theme.light) {
        this.$vuetify.theme.themes.light = Object.assign(this.$vuetify.theme.themes.light, value.theme.light);

        if (value.theme.room && value.theme.room.light) {
          const configs = value.theme.room.light;
          for (const config of configs) {
            document.documentElement.style.setProperty(config.code, config.value);
          }
        }

        if (value.theme.landing && value.theme.landing.light) {
          const configs = value.theme.landing.light;
          for (const config of configs) {
            document.documentElement.style.setProperty(config.code, config.value);
          }
        }

        if (value.theme.login && value.theme.login.light) {
          const configs = value.theme.login.light;
          for (const config of configs) {
            document.documentElement.style.setProperty(config.code, config.value);
          }
        }
      }
    },
    translation(value) {
      const currentTranslation = this.$vuetify.lang.locales[this.getCurrentLanguage()];
      const translationData = merge(currentTranslation, value);
      this.$vuetify.lang.locales[this.getCurrentLanguage()] = translationData;
    },
  },
  async created() {
    const vm = this;
    const [config, features] = (
      await Promise.allSettled([Config.getInstance(), FeatureDetection.getAllFeatures()])
    ).map((result) => result.value);

    let environment = '';
    try {
      const behalf = config.get('dav-saas-behalf-of');
      const parts = /(?<domain>.*)\/(?<module>.*)/.exec(behalf);

      environment = parts.groups.domain.endsWith('.dev') ? '.dev' : parts.groups.domain.endsWith('.hom') ? '.hom' : '';
    } catch (e) {
      ///
    }

    Vue.prototype.$dav = {
      isTotem: !!features?.isTotem,
      config,
      url: {
        get atendimento() {
          let attendanceUrl;
          if (vm.setup.url_domain_atendimento === undefined) {
            attendanceUrl = `${vm.setup.url_domain}.atendimento${environment}.dav.med.br`;
          } else {
            attendanceUrl = vm.setup.url_domain_atendimento;
          }
          return attendanceUrl;
        },
      },
    };

    this.loaded = true;
  },

  methods: {
    getCurrentLanguage() {
      const lang = navigator.language || navigator.userLanguage;
      let current;
      switch (lang) {
        case 'en':
        case 'en-US':
          current = 'en-US';
          break;

        case 'pt':
        case 'pt-BR':
        default:
          current = 'pt-BR';
          break;
      }
      return current;
    },
  },
};
</script>

<style>
@import url('//fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic|Material+Icons&display=swap');

.theme--light.v-application {
  background: #fcfcfc;
  overflow: hidden;
}
</style>
